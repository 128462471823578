import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

import "./delete.css"

const Deleteform = () => {

    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');


    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_3t3q0zn', 'template_20cwctj', form.current, {
                publicKey: '-_Qg_MpzEIBs3XU-q',
            })
            .then(
                () => {
                    alert('Thanks for submitting your details. Our executive will coordinate with you shortly.');
                    console.log('SUCCESS!');
                    setName('');
                    setNumber('');
                    setEmail('');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    return (
        <>
            <div className="delete">
                <div className="container">
                    <h4 style={{ textAlign: "center", marginTop: "20px", fontWeight: "700", fontSize: "30px" }}>Delete Your Account From Pioneer App</h4>

                    <form onSubmit={sendEmail} ref={form}>
                        <div className="form_fields">
                            <input
                                type="text"
                                placeholder="Your Name"
                                value={name}
                                name='user_name'
                                onChange={(e) => setName(e.target.value)}
                                className='form-control'
                                style={{ marginTop: "20px" }}
                                required
                            />

                            <input
                                type="number"
                                placeholder="Contact No."
                                value={number}
                                name='user_number'
                                onChange={(e) => setNumber(e.target.value)}
                                className='form-control'
                                style={{ marginTop: "20px" }}
                                required
                            />

                            <input
                                type="email"
                                placeholder="Your Email"
                                value={email}
                                name='user_email'
                                onChange={(e) => setEmail(e.target.value)}
                                className='form-control'
                                style={{ marginTop: "20px" }}
                                required
                            />

                            <button type='submit' value="send" className='btn_submit'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Deleteform