import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Footer from './components/footer';
import Header from './components/header';
import Home from './components/home';
import Privacypolicy from './pages/privacypolicy';
import Termsandcondition from './pages/termsandcondition';
import Deleteform from './pages/deleteform';

function App() {

  return (
    <>

      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/privacy" element={<Privacypolicy />} />
          <Route path='/terms' element={<Termsandcondition />} />
          <Route path='/delete' element={<Deleteform />} />
        </Routes>
        <Footer />
      </BrowserRouter>

    </>
  );
}

export default App;

