import React from 'react';
import  logo from "../assets/images/Pioneer-logoo.png"

const Footer = () => {
  return (
    <>
      <section id="footer">
        <div className="container">
          <div className="row">
            
              <div className="col-md-6">
                <div className="footer_1">
                 <img src={logo} alt="" className='footer-logo' />
                 <p className="footer-content">Pioneer Cement, a leading name in the construction industry, excels in producing high-quality cement products. With a commitment to innovation, sustainability, and reliability, Pioneer Cement is trusted by builders worldwide for its consistent performance and dedication to excellence.</p>
                </div>
              </div>


              <div className="col-md-6">
                <div className="footer_1">
                  <h2>Links</h2>
                  <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/privacy">Privacy Policy</a></li>
                    <li><a href="/terms">Terms & Condition</a></li>
                  </ul>
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
