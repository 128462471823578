import React from 'react';
import "./home.css";

import homeimg from "../assets/images/img.jpg"
import vission from "../assets/images/vision.jpg"
import icon2 from "../assets/images/icon2.jpg"
import icon3 from "../assets/images/icon3.jpg"
import icon4 from "../assets/images/icon4.png"

const Home = () => {
  return (
    <>
      <section className="home-page">
        <div className="home-img">
          <img src={homeimg} alt="img" className="bgimg" />
          <h1 className="home-heading">Download Pioneer Cement's app on Play Store for easy access to high-quality <br />
            cement products, industry news, and construction resources.</h1>
          <button className="btn">Download Now</button>
        </div>
      </section>




      <section className='properties-section'>
        <h1 className="property-heading">Our Services</h1>
        <div className="row top">
          <div className="col-md-11 col-12" style={{ margin: "auto" }}>
            <div className="row">
              <div className="col-md-3" style={{ textAlign: "center" }}>
                <div className="card">
                  <div className="cardimg">
                    <img src={vission} alt="" />
                  </div>
                  <div className="cardbody">
                    <h6 className='card-heading'>Vision</h6>
                    <p className='card-text'>Pioneer Cement views itself to become a multi-product facet company by end of 2024 and dealing in more than five different states by 2025 and having its Pan India presence by 2030.</p>
                  </div>
                </div>
              </div>

              <div className="col-md-3" style={{ textAlign: "center" }}>
                <div className="card">
                  <div className="cardimg">
                    <img src={icon2} alt="" />
                  </div>
                  <div className="cardbody">
                    <h6 className='card-heading'>Mission</h6>
                    <p className='card-text'>Our Mission is to give best in class products,We give utmost attention on the quality of the products. While developing our range, we keep in mind all the standards and guidelines set by industry. Owing to our superior quality, we have been able carved a niche in the market.</p>
                  </div>
                </div>
              </div>

              <div className="col-md-3" style={{ textAlign: "center" }}>
                <div className="card">
                  <div className="cardimg">
                    <img src={icon3} alt="" />
                  </div>
                  <div className="cardbody">
                    <h6 className='card-heading'>Quality</h6>
                    <p className='card-text'>Our constant focus on ensuring the right delivery makes us more rigid towards our quality certification, We also get laboratory tests for our products periodically to ensure the quality is flawless. we want to be a role model for all cement manufacturing Companies in India by delivering what we promise.</p>
                  </div>
                </div>
              </div>

              <div className="col-md-3" style={{ textAlign: "center" }}>
                <div className="card">
                  <div className="cardimg">
                    <img src={icon4} alt="" />
                  </div>
                  <div className="cardbody">
                    <h6 className='card-heading'>Values</h6>
                    <p className='card-text'>We believe a happy work force is the key to organizational success, at Pioneer we focus on mental and physical health of our employees by providing yearly health check-ups. We also focus on site safety and security of our work force, for us work ethics surpass everything and create a better environment for the future.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    </>
  )
}

export default Home;
